<div class="DTX_CONTENT_BLOCK_HOLDER" id="DTX_{{ contentId }}">
    @if (StyleCode === 'Coloured shapes') {
        <app-coloured-shapes />
    }
    @if (content) {
        <div
            [ngClass]="newsletterStyling ? 'DTX_CONTENT_BLOCK' : 'DTX_CONTENT_BLOCK ' + content.CssClasses"
            id="{{ content.Anchor }}"
        >
            @if (content.Title) {
                <app-title [contentTitle]="content.Title" />
            }
            @for (row of content.Rows; track row) {
                <div>
                    <app-row [row]="row" />
                </div>
            }
        </div>
    }
</div>
