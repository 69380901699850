import { NgClass } from '@angular/common';
import { Component,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';
import { ColouredShapesComponent } from '../coloured-shapes/coloured-shapes.component';
import { RowComponent } from '../row/row.component';
import { TitleComponent } from '../title/title.component';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  standalone: true,
  imports: [ColouredShapesComponent, NgClass, TitleComponent, RowComponent],
})
export class ContentComponent implements OnInit, OnDestroy {
  @Input() content!: PageContentDto;
  @Input() StyleCode!: string;
  @Input() newsletterStyling = false;
  contentId!: string;
  protected unsubscribe = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: InjectionToken<object>,
  ) {}

  ngOnInit() {
    this.contentId = this.content?.Id as string;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
